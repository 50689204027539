import Vue from 'vue'
import App from './App.vue'
import router from './router'
import CompositionAPI from '@vue/composition-api'
import 'vue2-toast/lib/toast.css';
import Toast from 'vue2-toast';
import YmCheckout from './assets/ymCheckout.umd.min'
Vue.config.productionTip = false
Vue.use(CompositionAPI)
Vue.use(Toast, {
  type: 'bottom',
  duration: 4000,
  wordWrap:true
});
Vue.use(YmCheckout)
window.$toast = Vue.prototype.$toast.bind(Vue)
window.$loading = Vue.prototype.$loading.bind(Vue)
window.open = null;
const _env = window._env = {
  buildTime: process.env.VUE_APP_BUILD,
  ver: process.env.VUE_APP_VER,
  isProduction: process.env.VUE_APP_ENV === 'production',
  isSandbox: process.env.VUE_APP_ENV === 'sandbox',
  env: process.env.VUE_APP_ENV,
  command: process.env.NODE_ENV
}
if (_env.isSandbox && _env.command==='production') {
  const s = document.createElement('script')
  s.src = 'https://cdn.jsdelivr.net/npm/vconsole@3.9.4/dist/vconsole.min.js'
  document.body.appendChild(s)
  s.onload = function () {
    new window.VConsole()
  }
}

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
