<template>
  <router-view />
</template>
<script>
export default {
  name: 'App',
  created () {
    const img = new Image(0,0)
    img.src='/img/payermax_btn_logo.png'
  }
}
</script>
