import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
    {
      path: '/',
      name: 'Home',
      component:  () => import('./views/index.vue')
    },
    {
        path: '/result4payermax',
        name: 'Result',
        component:  () => import('./views/result4payermax.vue')
    }
]
const createRouter = () => new VueRouter({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: routes
})
const router = createRouter()


export default router
